
<template>
  <!-- Navbar -->
  <nav :class="_prepareNavbarClass()">
    <div class="container py-0">
      <div class="navbar-translate">
        <a
          class="navbar-brand publicHeader"
          href="#"
          rel="tooltip"
          data-placement="bottom"
          @click="$router.push('/home')"
        >
          <img
            src="../../../assets/lc_img/Lets_Connect_with_text.png"
            alt="alt"
          >
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-controls="navigation-index"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-bar top-bar" />
          <span class="navbar-toggler-bar middle-bar" />
          <span class="navbar-toggler-bar bottom-bar" />
        </button>
      </div>
      <div
        class="collapse navbar-collapse"
        data-nav-image="../../assets/one_img/blurred-image-1.jpg"
        data-color="orange"
      >
        <ul class="navbar-nav ml-auto align-menus">
          <li class="nav-item">
            <a
              href="#"
              class="nav-link active"
              data-toggle="dropdown"
              @click="$router.push('/services')"
            >
              Services
            </a>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              data-toggle="dropdown"
              @click="$router.push('/plans')"
            >
              Pricing
            </a>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              data-toggle="dropdown"
              @click="$router.push('/about-us')"
            >
              About Us
            </a>
          </li>
          <li class="nav-item mr-3">
            <a
              href="#"
              class="nav-link"
              data-toggle="dropdown"
              @click="$router.push('/contact-us')"
            >
              Contact Us
            </a>
          </li>

          <li
            v-if="!isUserLoggedIn"
            class="nav-item mr-3"
          >
            <a
              href="#"
              class="button-dark-outline"
              data-toggle="dropdown"
              @click="$router.push('/login')"
            >
              Login
              <div class="icon-wrapper">
                <img
                  src="../../../assets/lc_img/icons/sign-in.svg"
                  alt=""
                >
              </div>
            </a>
          </li>
          <li
            v-if="!isUserLoggedIn"
            class="nav-item mr-3"
          >
            <a
              href="#"
              class="button-dark-solid"
              data-toggle="dropdown"
              @click="$router.push('/login')"
            >
              Create an account
              <div class="icon-wrapper">
                <img
                  src="../../../assets/lc_img/icons/sign-in-white.svg"
                  alt=""
                >
              </div>
            </a>
          </li>

          <li
            v-if="isUserLoggedIn"
            class="nav-item ml-3"
          >
            <a
              class="nav-link btn btn-primary"
              href="#"
              @click="$router.push('/pages/dashboard')"
            >
              <p>Dashboard</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>

  export default {
    name: 'PublicHeader',
    props: {
      mode: {
        type: String,
        default: 'transparent',
      },
    },
    data: () => ({
      isUserLoggedIn: false,
    }),
    computed: {},
    created () {
      this.checkIfUserLoggedIn()
    },
    methods: {
      checkIfUserLoggedIn () {
        const username = localStorage.getItem('username')
        if (username && username.length) {
          this.isUserLoggedIn = true
        }
      },
      redirectToPage (path) {
        window.location = window.location.origin + path
      },
      _prepareNavbarClass () {
        switch (this.mode) {
          case 'transparent': {
            return 'navbar navbar-expand-lg navbar-absolute'
          }
          case 'dark': {
            return 'navbar navbar-expand-lg'
          }
        }
      },
    },
  }

</script>
<style lang="scss">
  .navbar-main {
    background: #fff !important;
    height: 98px !important;
    position: fixed!important;
    padding: 0rem 1rem !important;
  }

  .publicHeader {
    img {
      height: 60px;
    }
  }

  .align-menus {
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    .align-menus {
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 425.98px) {
    .publicHeader {
      img {
        height: 45px;
      }
    }

    .navbar p {
      line-height: 1.8em;
      font-size: 1em;
      font-weight: 400;
      text-transform: none;
    }

    .navbar-main {
      height: 75px !important;
      padding: 0.5rem 1rem !important;
    }
  }

</style>
